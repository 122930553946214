/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const SAVE_INSPIRATIONS = 'SAVE_INSPIRATIONS';
export const SAVE_INSPIRATION = 'SAVE_INSPIRATION';
export const SAVE_INSPIRATIONS_FILTERS = 'SAVE_INSPIRATIONS_FILTERS';
export const SET_INSPIRATION_LOADING = 'SET_INSPIRATION_LOADING';

/**
 * Send message
 * @param {Object} data
 * @namespace Satisfly/Store/Inspirations/Action/updateInspiration */
export const updateInspiration = (data = []) => ({
    type: SAVE_INSPIRATION,
    data
});

/** @namespace Satisfly/Store/Inspirations/Action/updateInspirations */
export const updateInspirations = (data = []) => ({
    type: SAVE_INSPIRATIONS,
    data
});

/** @namespace Satisfly/Store/Inspirations/Action/saveInspirationFilters */
export const saveInspirationFilters = (data = []) => ({
    type: SAVE_INSPIRATIONS_FILTERS,
    data
});

/** @namespace Satisfly/Store/Inspirations/Action/setLoading */
export const setLoading = (data) => ({
    type: SET_INSPIRATION_LOADING,
    data
});
