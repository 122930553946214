/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { App as SourceApp } from 'SourceComponent/App/App.component';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const AB_TEST_VERSION = 'AB_TEST_VERSION';

/** @namespace Satisfly/Component/App/Component */
export class App extends SourceApp {
    __construct(props) {
        super.__construct(props);

        this.configureAppBasedOnEnvironment();
        this.configureApp();
        const { ab_test } = window.contentConfiguration || {};
        const isABTestEnabled = ab_test?.enabled === '1';
        const currentVersion = BrowserDatabase.getItem(AB_TEST_VERSION);

        if (isABTestEnabled && !currentVersion) {
            const rand = Math.floor(Math.random() * 100) + 1;
            const version = rand <= ab_test?.a_percent ? 'a' : 'b';

            BrowserDatabase.setItem(
                version,
                AB_TEST_VERSION,
                ONE_MONTH_IN_SECONDS
            );
        }

        if (!isABTestEnabled) {
            BrowserDatabase.deleteItem(AB_TEST_VERSION);
        }
    }
}

export default App;
