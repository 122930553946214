/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

import Field from './Field.component';

/**
 * Field
 * @class FieldContainer
 * @namespace Satisfly/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...super.propTypes,
        forceCloseDropdown: PropTypes.bool,
        step: PropTypes.number,
        hideVariantsDialog: PropTypes.func,
        absoluteLabel: PropTypes.node
    };

    static defaultProps = {
        ...super.defaultProps,
        forceCloseDropdown: false,
        step: 1,
        hideVariantsDialog: () => {},
        absoluteLabel: null
    };

    containerProps() {
        const {
            events,
            validateOn,
            type,
            attr: {
                autoComplete,
                autocomplete,
                ...attr
            } = {},
            isDisabled,
            mix,
            value,
            options,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            changeValueOnDoubleClick,
            isSortSelect,
            forceCloseDropdown,
            step,
            hideVariantsDialog,
            absoluteLabel
        } = this.props;
        const { validationResponse, lengthError } = this.state;
        const { validate } = this.containerFunctions;

        const newEvents = { ...events };

        validateOn.forEach((eventName) => {
            const { [eventName]: baseEvent } = events;

            newEvents[eventName] = baseEvent ? this.validateOnEvent.bind(this, baseEvent) : validate;
        });

        return {
            type,
            attr: {
                ...attr,
                autoComplete: autoComplete || autocomplete
            },
            value,
            isDisabled,
            mix,
            options,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            changeValueOnDoubleClick,
            isSortSelect,
            validationResponse,
            resetFieldValue: this.resetFieldValue.bind(this),
            events: newEvents,
            fieldRef: this.fieldRef,
            setRef: this.setRef.bind(this),
            lengthError,
            forceCloseDropdown,
            step,
            hideVariantsDialog,
            absoluteLabel
        };
    }

    render() {
        return (
            <Field
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default FieldContainer;
