/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { AB_TEST_VERSION } from 'Component/App/App.component';
import BrowserDatabase from 'Util/BrowserDatabase';
import { Field } from 'Util/Query';

/**
 * CMS Blocks Query
 * @class CmsBlocksQuery
 * @namespace Satisfly/Query/CmsBlock/Query */
export class CmsBlockQuery {
    /**
     * get CMS Block query
     * @param  {{identifier: String, title: String, content: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Field} CMS Block query
     * @memberof CmsBlocksQuery
     */
    getQuery({ identifiers }) {
        if (!identifiers) {
            throw new Error('Missing argument `options`');
        }

        const { ab_test } = window.contentConfiguration || {};
        const isABTestEnabled = ab_test?.enabled === '1';

        const query = new Field('cmsBlocks')
            .addArgument('identifiers', '[String]', identifiers)
            .addField(this._getItemsField())
            .setAlias('cmsBlocks');

        if (isABTestEnabled) {
            const version = BrowserDatabase.getItem(AB_TEST_VERSION);

            query.addArgument('additional', 'CmsBlocksInput', { version });
        }

        return query;
    }

    _getItemFields() {
        return [
            'title',
            'content',
            'identifier',
            'disabled'
        ];
    }

    _getItemsField() {
        return new Field('items')
            .addFieldList(this._getItemFields());
    }
}

export default new CmsBlockQuery();
