/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    HEADER_CMS_BLOCK as SOURCE_HEADER_CMS_BLOCK,
    SCROLL_DEBOUNCE_DELAY as SOURCE_SCROLL_DEBOUNCE_DELAY
} from 'SourceComponent/Menu/Menu.config';

export const SCROLL_DEBOUNCE_DELAY = SOURCE_SCROLL_DEBOUNCE_DELAY;
export const HEADER_CMS_BLOCK = SOURCE_HEADER_CMS_BLOCK;
export const PHONE_CONTACT_CMS = 'header-contact';
