/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { MenuItemContainer as SourceMenuItemContainer } from 'SourceComponent/MenuItem/MenuItem.container';

import MenuItem from './MenuItem.component';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Breadcrumbs/Breadcrumbs.dispatcher'
);

/** @namespace Satisfly/Component/MenuItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Satisfly/Component/MenuItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateBreadcrumbs: () => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.update([], dispatch)
    )
});

/** @namespace Satisfly/Component/MenuItem/Container */
export class MenuItemContainer extends SourceMenuItemContainer {
    static propTypes = {
        ...super.propTypes,
        arrowDownIcon: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        arrowDownIcon: false
    };

    containerProps() {
        const {
            activeMenuItemsStack,
            device,
            arrowDownIcon,
            isExpandable,
            isLink,
            item,
            itemMods
        } = this.props;

        return {
            activeMenuItemsStack,
            device,
            arrowDownIcon,
            isExpandable,
            isLink,
            item,
            itemMods
        };
    }

    render() {
        return (
            <MenuItem
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);
