/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ProductListQuery as SourceQuery } from 'SourceQuery/ProductList.query';
import { Field, Fragment } from 'Util/Query';

/**
 * Product List Query
 * @class ProductListQuery
 * @namespace Satisfly/Query/ProductList/Query */
export class ProductListQuery extends SourceQuery {
    _getFilterArgumentMap() {
        return {
            categoryIds: (id) => ({ category_id: { eq: id } }),
            categoryUrlPath: (url) => ({ category_url_path: { eq: url } }),
            priceRange: ({ min, max }) => {
                const price = {};

                if (min) {
                    price.from = min;
                }

                if (max) {
                    price.to = max;
                }

                return { price };
            },
            productsSkuArray: (sku) => ({ sku: { in: sku } }),
            productSKU: (sku) => ({ sku: { eq: sku } }),
            productID: (id) => ({ id: { eq: id } }),
            productUrlPath: (url) => ({ url_key: { eq: url } }),
            customFilters: this._getCustomFilters.bind(this),
            newToDate: (date) => ({ news_to_date: { gteq: date } }),
            conditions: (conditions) => ({ conditions: { eq: conditions } }),
            customerGroupId: (id) => ({ customer_group_id: { eq: id } }),
            subiektId: (id) => ({ subiekt_id: { eq: id } })
        };
    }

    _getStockItemFields() {
        return [
            'in_stock',
            'min_sale_qty',
            'max_sale_qty',
            'is_qty_decimal',
            new Field('qty_increments_float').setAlias('qty_increments')
        ];
    }

    _getCartProductInterfaceFields() {
        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'url',
            'salable_qty',
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getCartConfigurableProductFragment(),
            this._getAttributesField(false, true),
            this._getProductLinksField(),
            this._getPriceRangeField()
        ];
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false, withoutGallery = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false
        } = this.options;

        // set option to always request images for product variants if they're requested for wishlist
        if (isForWishlist) {
            this.options.isForWishlist = true;
        }

        // Basic fields returned always
        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'salable_qty',
            'created_at',
            this._getStockItemField(),
            this._getPriceRangeField(),
            this._getEstimatedDeliveryTimeField()
        ];

        if (!isSingleProduct) {
            fields.push(
                this._getProductHoverImageField()
            );
        }

        if (isPlp) {
            fields.push(
                this._getProductSmallField(),
                this._getTierPricesField()
            );
        }

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if ((!isPlp || isForWishlist) && !withoutGallery) {
            fields.push(
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getUrlRewritesFields(),
                this._getCustomizableProductFragment(),
                this._getLinkedProductsField(),
                this._getLabelsFields()
            );

            // if variants are not needed
            if (!noVariants && !isPlp) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems()
                );
            }

            if (isPlp) {
                fields.push(
                    this._getConfigurableProductFragment(isPlp)
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct && !withoutGallery) {
            fields.push(
                'stock_status',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField()
                );
            }
        }

        return fields;
    }

    _getLinkedProductsField() {
        return new Field('linked_products')
            .addFieldList([
                'id',
                'sku',
                'name',
                'image_path',
                'url_key',
                'status',
                'type_id'
            ]);
    }

    _getLabelsFields() {
        return new Field('labels ')
            .addFieldList([
                'id',
                'name',
                'listing',
                'css_class',
                'font_color',
                'background_color'
            ]);
    }

    _getConfigurableProductFragment(isPlp = false) {
        return new Fragment('ConfigurableProduct')
            .addFieldList(this._getConfigurableProductFragmentFields(isPlp));
    }

    _getConfigurableProductFragmentFields(isPlp = false) {
        return [
            this._getConfigurableOptionsField(),
            !isPlp ? this._getVariantsField() : this._getVariantsPLP()
        ];
    }

    _getVariantsPLP() {
        return new Field('variants')
            .setAlias('variants')
            .addFieldList([this._getProductPLPField()]);
    }

    _getProductPLPField() {
        return new Field('product')
            .addFieldList(['sku']);
    }

    _getProductsField() {
        const products = new Field('products')
            .addFieldList(this._getProductFields());

        if (this.options.isSearch) {
            products.addField(this._getCategoriesFields());
        }

        this._getProductArguments().forEach((arg) => products.addArgument(...arg));

        return products;
    }

    _getCategoriesFields() {
        return new Field('categories_matched')
            .addFieldList([
                'name',
                'url_key',
                'url_path',
                'product_count'
            ]);
    }

    _getEstimatedDeliveryTimeField() {
        return new Field('edd_data')
            .addFieldList(this._getEstimatedDeliveryTimeFields());
    }

    _getEstimatedDeliveryTimeFields() {
        return [
            'date',
            'deadline_hour',
            'deadline_date',
            'estimated_before',
            'estimated_after'
        ];
    }

    _getProductHoverImageField() {
        return new Field('hover_image')
            .addFieldList(this._getProductHoverImageFields());
    }

    _getProductHoverImageFields() {
        return [
            'path',
            'url'
        ];
    }

    _getProductFields() {
        const {
            requireInfo, isSingleProduct, notRequireInfo, isSearch
        } = this.options;

        if (isSearch) {
            return [
                this._getSearchItemsField()
            ];
        }

        // do not request total count for PDP
        if (isSingleProduct || notRequireInfo) {
            return [
                this._getItemsField()
            ];
        }

        // for filters only request
        if (requireInfo) {
            return [
                this._getSortField(),
                this._getAggregationsField()
            ];
        }

        return [
            'total_count',
            this._getItemsField(),
            this._getPageInfoField()
        ];
    }

    _getSearchItemsField() {
        const items = new Field('items')
            .addFieldList(this._getSearchInterfaceFields());

        return items;
    }

    _getSearchInterfaceFields() {
        const fields = [
            'sku',
            'name',
            'url',
            this._getProductThumbnailField()
        ];

        return fields;
    }

    _getVariantFields() {
        const {
            isSingleProduct
        } = this.options;

        return [
            this._getProductField(isSingleProduct)
        ];
    }

    _getProductField(withoutGallery = false) {
        const { isForLinkedProducts, isForWishlist = false } = this.options;

        return new Field('product')
            .addFieldList(this._getProductInterfaceFields(true, isForLinkedProducts, isForWishlist, withoutGallery));
    }
}

export default new ProductListQuery();
