/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    CART as SOURCE_CART,
    CART_EDITING as SOURCE_CART_EDITING,
    CART_OVERLAY as SOURCE_CART_OVERLAY,
    CATEGORY as SOURCE_CATEGORY,
    CHECKOUT as SOURCE_CHECKOUT,
    CHECKOUT_ACCOUNT as SOURCE_CHECKOUT_ACCOUNT,
    CHECKOUT_SUCCESS as SOURCE_CHECKOUT_SUCCESS,
    CMS_PAGE as SOURCE_CMS_PAGE,
    CONTACT_US as SOURCE_CONTACT_US,
    CUSTOMER_ACCOUNT as SOURCE_CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE as SOURCE_CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_ORDER as SOURCE_CUSTOMER_ORDER,
    CUSTOMER_SUB_ACCOUNT as SOURCE_CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_WISHLIST as SOURCE_CUSTOMER_WISHLIST,
    FILTER as SOURCE_FILTER,
    HOME_PAGE as SOURCE_HOME_PAGE,
    MENU as SOURCE_MENU,
    MENU_SUBCATEGORY as SOURCE_MENU_SUBCATEGORY,
    MY_ACCOUNT as SOURCE_MY_ACCOUNT,
    NO_MATCH as SOURCE_NO_MATCH,
    PDP as SOURCE_PDP,
    POPUP as SOURCE_POPUP,
    PRODUCT_COMPARE as SOURCE_PRODUCT_COMPARE,
    SEARCH as SOURCE_SEARCH
} from 'SourceComponent/Header/Header.config';

export const PDP = SOURCE_PDP;

export const POPUP = SOURCE_POPUP;

export const CATEGORY = SOURCE_CATEGORY;

export const CUSTOMER_ACCOUNT = SOURCE_CUSTOMER_ACCOUNT;

export const CUSTOMER_SUB_ACCOUNT = SOURCE_CUSTOMER_SUB_ACCOUNT;

export const CUSTOMER_ACCOUNT_PAGE = SOURCE_CUSTOMER_ACCOUNT_PAGE;

export const CUSTOMER_WISHLIST = SOURCE_CUSTOMER_WISHLIST;

export const CUSTOMER_ORDER = SOURCE_CUSTOMER_ORDER;

export const HOME_PAGE = SOURCE_HOME_PAGE;

export const MENU = SOURCE_MENU;

export const MENU_SUBCATEGORY = SOURCE_MENU_SUBCATEGORY;

export const SEARCH = SOURCE_SEARCH;

export const FILTER = SOURCE_FILTER;

export const CART = SOURCE_CART;

export const CART_OVERLAY = SOURCE_CART_OVERLAY;

export const CART_EDITING = SOURCE_CART_EDITING;

export const CHECKOUT = SOURCE_CHECKOUT;

export const CHECKOUT_SUCCESS = SOURCE_CHECKOUT_SUCCESS;

export const CHECKOUT_ACCOUNT = SOURCE_CHECKOUT_ACCOUNT;

export const CMS_PAGE = SOURCE_CMS_PAGE;

export const MY_ACCOUNT = SOURCE_MY_ACCOUNT;

export const NO_MATCH = SOURCE_NO_MATCH;

export const CONTACT_US = SOURCE_CONTACT_US;

export const PRODUCT_COMPARE = SOURCE_PRODUCT_COMPARE;

export const DEFAULT_TOP_MENU_BREAKPOINT = 300;

export const TOP_BANNER_CMS = 'top-banner';
export const SEARCH_MENU = 'search_menu';
export const SUB_MENU = 'sub_menu';
