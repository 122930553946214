/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { AB_TEST_VERSION } from 'Component/App/App.component';
import BrowserDatabase from 'Util/BrowserDatabase';
import { Field } from 'Util/Query';

/**
 * Menu Query
 * @class MenuQuery
 * @namespace Satisfly/Query/Menu/Query */
export class MenuQuery {
    /**
     * get Menu query
     * @param  {{identifier: String}} options A object containing different aspects of query, each item can be omitted
     * @return {Field} Menu query
     * @memberof MenuQuery
     */
    getQuery({ identifier }) {
        const query = new Field('scandiwebMenu')
            .addArgument('identifier', 'String!', identifier)
            .addFieldList(this._getMenuFields())
            .setAlias('menu');

        const { ab_test } = window.contentConfiguration || {};
        const isABTestEnabled = ab_test?.enabled === '1';

        if (isABTestEnabled) {
            const version = BrowserDatabase.getItem(AB_TEST_VERSION);

            query.addArgument('additional', 'ScandiwebMenuInput', { version });
        }

        return query;
    }

    _getMenuFields() {
        return [
            'menu_id', 'is_active', 'css_class', this._getMenuItemsField()
        ];
    }

    _getMenuItemsField() {
        return new Field('items')
            .addFieldList(this._getMenuItemFields());
    }

    _getMenuItemFields() {
        return [
            'url',
            'icon',
            'title',
            'item_id',
            'position',
            'url_type',
            'parent_id',
            'is_active',
            'item_class',
            'category_id',
            'cms_page_identifier'
        ];
    }
}

export default new MenuQuery();
