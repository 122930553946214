/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import { ITEM_CAPTION } from 'Component/SideMenu/SideMenu.config';
import { MenuItem as SourceMenuItem } from 'SourceComponent/MenuItem/MenuItem.component';
import { ReactComponent as ArrowDownIcon } from 'Style/icons/arrow-down.svg';
import { ReactComponent as ArrowRightIcon } from 'Style/icons/chevron-right.svg';
import media from 'Util/Media';

/** @namespace Satisfly/Component/MenuItem/Component */
export class MenuItem extends SourceMenuItem {
    static propTypes = {
        ...super.propTypes,
        arrowDownIcon: PropTypes.bool,
        device: PropTypes.isRequired
    };

    static defaultProps = {
        arrowDownIcon: false
    };

    renderArrowDownIcon() {
        const { device, arrowDownIcon } = this.props;

        if (!device.isMobile && arrowDownIcon) {
            return (
                <ArrowDownIcon />
            );
        }
    }

    renderExpandButton() {
        const { isExpandable, itemMods } = this.props;

        if (!isExpandable) {
            return null;
        }

        return (
            <figcaption
              block="SideMenuItem"
              elem="ExpandedState"
              mods={ itemMods }
            >
                <ArrowRightIcon />
            </figcaption>
        );
    }

    renderItemContent(item, itemMods) {
        const { icon, title, item_class } = item;
        const iconSrc = icon ? media(icon) : null;

        return (
            <figcaption
              block="Menu"
              elem="ItemCaption"
              mods={ itemMods }
              mix={ { block: item_class } }
            >
                { iconSrc && (
                    <img
                      block="Menu"
                      elem="ItemIcon"
                      src={ iconSrc }
                      alt={ title }
                    />
                ) }
                { title }
                { this.renderArrowDownIcon() }
                { this.renderExpandButton() }
            </figcaption>
        );
    }

    render() {
        const { item, itemMods, isLink } = this.props;

        if (isLink && item?.item_class !== ITEM_CAPTION) {
            return this.renderItemLinkContent();
        }

        return this.renderItemContent(item, itemMods);
    }
}

export default MenuItem;
