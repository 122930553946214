/** * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
export const UPDATE_SHIPPING_FIELDS = 'UPDATE_SHIPPING_FIELDS';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_AVAILABLE = 'UPDATE_EMAIL_AVAILABLE';
export const SET_IS_COMPANY = 'SET_IS_COMPANY';
export const SET_PAYMENT_FORM_VALID = 'SET_PAYMENT_FORM_VALID';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';

/** @namespace Satisfly/Store/Checkout/Action/updateShippingFields */
export const updateShippingFields = (shippingFields) => ({
    type: UPDATE_SHIPPING_FIELDS,
    shippingFields
});

/** @namespace Satisfly/Store/Checkout/Action/updateEmail */
export const updateEmail = (email) => ({
    type: UPDATE_EMAIL,
    email
});

/** @namespace Satisfly/Store/Checkout/Action/updateEmailAvailable */
export const updateEmailAvailable = (isEmailAvailable) => ({
    type: UPDATE_EMAIL_AVAILABLE,
    isEmailAvailable
});

/** @namespace Satisfly/Store/Checkout/Action/updateBuyer */
export const updateBuyer = (isCompany) => ({
    type: SET_IS_COMPANY,
    isCompany
});

/** @namespace Satisfly/Store/Checkout/Action/updatePaymentFormValid */
export const updatePaymentFormValid = (isPaymentFormValid) => ({
    type: SET_PAYMENT_FORM_VALID,
    isPaymentFormValid
});

/** @namespace Satisfly/Store/Checkout/Action/setPaymentMethod */
export const setPaymentMethod = (paymentMethod) => ({
    type: SET_PAYMENT_METHOD,
    paymentMethod
});
