/* eslint-disable no-magic-numbers */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Link from 'Component/Link';
import MenuIcon from 'Component/MenuIcon';
import MenuItem from 'Component/MenuItem';
import CmsBlock from 'SourceComponent/CmsBlock';
import { Menu as SourceMenu } from 'SourceComponent/Menu/Menu.component';
import { ReactComponent as ArrowLeftIcon } from 'Style/icons/arrow-left.svg';
import { getSortedItems } from 'Util/Menu';

import { PHONE_CONTACT_CMS } from './Menu.config';

import './Menu.style';

/** @namespace Satisfly/Component/Menu/Component */
export class Menu extends SourceMenu {
    static propTypes = {
        ...super.propTypes,
        isTopHeaderVisible: PropTypes.bool,
        handleOpenSideMenu: PropTypes.func.isRequired
    };

    renderSubLevel(category, isSecondLevel = false) {
        const { activeMenuItemsStack, device } = this.props;
        const { item_id, children, url } = category;
        const childrenArray = getSortedItems(Object.values(children));
        const isVisible = activeMenuItemsStack.includes(item_id);
        const subcategoryMods = { type: 'subcategory' };

        return (
            <div
              block="Menu"
              elem="SubMenu"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="ItemList"
                  mods={ { ...subcategoryMods } }
                >
                    { childrenArray.map((item) => this.renderSubLevelItems(item, isSecondLevel)) }
                    { (isSecondLevel || !device.isMobile) && (
                        <div
                          block="Menu"
                          elem="Item"
                          mods={ { SeeAll: true } }
                          key={ item_id }
                        >
                            <Link
                              to={ url }
                              block="Menu"
                              elem="Link"
                              id={ item_id }
                            >
                                { __('See all') }
                                <ArrowLeftIcon />
                            </Link>
                        </div>
                    ) }
                </div>
            </div>
        );
    }

    renderSubLevelItems(item, isSecondLevel) {
        const {
            handleSubcategoryClick,
            activeMenuItemsStack,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        const { item_id, children } = item;
        const childrenArray = Object.values(children);
        const subcategoryMods = { type: 'subcategory', isSecondLevel };
        const singleSubcategoryMods = { type: 'single_subcategory', isSecondLevel };
        const areChildren = childrenArray.length !== 0;

        if (childrenArray.length && device.isMobile) {
            return (
                <div
                  key={ item_id }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  role="button"
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      itemMods={ { ...subcategoryMods, isExpanded: activeMenuItemsStack.includes(item_id) } }
                      onCategoryHover={ onCategoryHover }
                      closeMenu={ closeMenu }
                      isExpandable
                    />
                    { this.renderSubLevel(item) }
                </div>
            );
        }

        return (
            <div
              block="Menu"
              elem="SubItemWrapper"
              key={ item_id }
              onClick={ this.stopPropagation }
              role="button"
              tabIndex="-1"
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  itemMods={ areChildren ? subcategoryMods : singleSubcategoryMods }
                  closeMenu={ closeMenu }
                  isLink
                />
                { this.renderDesktopSubLevel(item) }
            </div>
        );
    }

    renderFirstLevelItems(item) {
        const {
            activeMenuItemsStack,
            handleSubcategoryClick,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        const { children, item_id } = item;
        const childrenArray = Object.values(children);
        const itemMods = { type: 'main' };

        if (childrenArray.length && device.isMobile) {
            return (
                <div
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  block="Menu"
                  elem="SubCatLink"
                  role="button"
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      itemMods={ { ...itemMods, isExpanded: activeMenuItemsStack.includes(item_id) } }
                      onCategoryHover={ onCategoryHover }
                      closeMenu={ closeMenu }
                      isExpandable
                    />
                </div>
            );
        }

        if (childrenArray.length) {
            return (
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  itemMods={ itemMods }
                  onCategoryHover={ onCategoryHover }
                  closeMenu={ closeMenu }
                  isLink
                  arrowDownIcon
                />
            );
        }

        return (
            <MenuItem
              activeMenuItemsStack={ activeMenuItemsStack }
              item={ item }
              itemMods={ itemMods }
              onCategoryHover={ onCategoryHover }
              closeMenu={ closeMenu }
              isLink
            />
        );
    }

    renderTopLevel() {
        const {
            menu,
            isTopHeaderVisible,
            device,
            activeMenuItemsStack
        } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));
        const bigElements = childrenArray.filter((elem) => elem.item_class.includes('big_'));
        const restElements = childrenArray.filter((elem) => !elem.item_class.includes('big_'));
        const selectedSubMenu = children?.[activeMenuItemsStack?.[0]] || null;

        return (
            <div
              block="Menu"
              elem="MainCategories"
              mods={ { isTopHeaderHidden: !isTopHeaderVisible } }
            >
                    { !device.isMobile && this.renderSideMenuIcon() }
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main', active: !selectedSubMenu } }
                      aria-label={ mainCategoriesTitle }
                    >
                        { device.isMobile ? (
                            <>
                                { bigElements?.length && (
                                    <li block="MenuItem" elem="BigLinkWrapper">
                                        { bigElements.map((item) => (
                                            <Link
                                              to={ item.url }
                                              block="MenuItem"
                                              elem="Link"
                                              id={ item.item_id }
                                            >
                                                <span>{ item.title }</span>
                                                <span>{ item.item_class.split('_')?.[1] || '' }</span>
                                            </Link>
                                        )) }
                                    </li>
                                ) }
                                { restElements.map((item) => (
                                    <li
                                      block="Menu"
                                      elem="Item"
                                      mix={ { block: item.item_class } }
                                      key={ item.item_id }
                                    >
                                        { this.renderFirstLevel(item) }
                                    </li>

                                )) }
                            </>
                        ) : (
                            childrenArray.map((item) => (
                                <li
                                  block="Menu"
                                  elem="Item"
                                  mix={ { block: item.item_class } }
                                  key={ item.item_id }
                                >
                                    { this.renderFirstLevel(item) }
                                    { activeMenuItemsStack.includes(item.item_id) && this.renderSubMenuDesktop(children) }
                                </li>
                            ))
                        ) }
                    </ul>
                    { device.isMobile && (
                        <div block="Menu" elem="SubItemList" mods={ { active: !!selectedSubMenu } }>
                            { selectedSubMenu && this.renderSubLevel(selectedSubMenu, true) }
                        </div>
                    ) }
                    { this.renderContactSection() }
            </div>
        );
    }

    renderSubMenuDesktopItems(item) {
        const { item_id, children } = item;

        if (!Object.keys(children).length) {
            return null;
        }

        const { activeMenuItemsStack } = this.props;
        const isVisible = activeMenuItemsStack.includes(item_id);

        if (!isVisible) {
            return null;
        }

        return (
            <div
              block="Menu"
              elem="SubCategoriesWrapper"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="SubCategoriesWrapperInner"
                  mods={ { isVisible } }
                >
                    <div
                      block="Menu"
                      elem="SubCategories"
                    >
                        { this.renderSubLevel(item) }
                    </div>
                </div>
            </div>
        );
    }

    renderContactSection = () => {
        const {
            device: { isMobile },
            isTopHeaderVisible
        } = this.props;

        if (isMobile) {
            return null;
        }

        return (
            <div
              block="Menu"
              elem="ContactDesktop"
              mods={ { isTopHeaderVisible: !!isTopHeaderVisible } }
            >
                <CmsBlock identifier={ PHONE_CONTACT_CMS } blockType="phone_contact" />
            </div>
        );
    };

    renderSideMenuIcon() {
        const { handleOpenSideMenu } = this.props;

        return (
            <div
              block="Menu"
              elem="SideMenuIcon"
              onClick={ handleOpenSideMenu }
            >
                <MenuIcon isActive />
                { __('Menu') }
            </div>
        );
    }

    renderFirstLevel(item) {
        return this.renderFirstLevelItems.call(this, item);
    }

    render() {
        const { closeMenu } = this.props;

        return (
            <div
              block="Menu"
              elem="MenuWrapper"
              onMouseLeave={ closeMenu }
            >

              { this.renderTopLevel() }
            </div>
        );
    }
}

export default Menu;
