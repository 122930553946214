/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    MenuIcon as SourceMenuIcon
} from 'SourceComponent/MenuIcon/MenuIcon.component';

/** @namespace Satisfly/Component/MenuIcon/Component */
export class MenuIcon extends SourceMenuIcon {
    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="MenuIcon"
              mods={ { isActive } }
              width="24"
              height="24"
              viewBox="0 0 50 50"
              fill="none"
              stroke="#000000"
              strokeWidth="2.5"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path fill="#231F20" d="M8.667,15h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,15,8.667,15z" />
                <path fill="#231F20" d="M8.667,37h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,37,8.667,37z" />
                <path fill="#231F20" d="M8.667,26h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,26,8.667,26z" />
            </svg>
        );
    }
}

export default MenuIcon;
