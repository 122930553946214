/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import { FieldNumberWithControlsContainer as SourceContainer } from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.container';

import FieldNumberWithControls from './FieldNumberWithControls.component';

/**
 * Field Number With Controls
 * @class FieldNumberWithControlsContainer
 * @namespace Satisfly/Component/FieldNumberWithControls/Container */
export class FieldNumberWithControlsContainer extends SourceContainer {
    static propTypes = {
        ...super.propTypes,
        step: PropTypes.number
    };

    static defaultProps = {
        step: 1
    };

    handleValueChange(value) {
        const {
            events: { onChange } = {}
        } = this.props;

        // eslint-disable-next-line no-magic-numbers
        const numberValue = Math.round(value * 100) / 100;
        const newValue = this.setValue(numberValue);

        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    }

    containerProps() {
        const {
            attr: {
                autoComplete,
                autocomplete,
                defaultValue,
                ...attr
            } = {},
            value,
            events,
            setRef,
            isDisabled,
            step
        } = this.props;

        const { value: stateValue } = this.state;

        return {
            attr: {
                ...attr,
                autoComplete: autoComplete || autocomplete
            },
            value,
            events,
            setRef,
            isDisabled,
            stateValue,
            step
        };
    }
}

export default FieldNumberWithControls;
