/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

/**
 * Field Select
 * @class FieldSelectContainer
 * @namespace Satisfly/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    static propTypes = {
        ...super.propTypes,
        forceCloseDropdown: PropTypes.bool,
        hideVariantsDialog: PropTypes.func,
        absoluteLabel: PropTypes.node
    };

    static defaultProps = {
        ...super.defaultProps,
        forceCloseDropdown: false,
        hideVariantsDialog: null,
        absoluteLabel: null
    };

    handleSelectListOptionClick(option) {
        const { changeValueOnDoubleClick, events: { onChange } = {}, forceCloseDropdown } = this.props;
        const { value, target: { value: targetValue } = {} } = option;

        const fieldValue = value || targetValue || '';

        if (changeValueOnDoubleClick) {
            this.fieldRef.value = this.fieldRef.value === value ? '' : fieldValue;
        } else {
            this.fieldRef.value = fieldValue;
        }

        if (onChange) {
            onChange(fieldValue);
        }

        if (forceCloseDropdown) {
            this.setState({ isExpanded: false });
        }
    }

    handleSelectExpandedExpand() {
        const { hideVariantsDialog } = this.props;
        const { isExpanded } = this.state;

        hideVariantsDialog();

        if (isExpanded) {
            this.handleSelectExpand();
        }
    }

    containerProps() {
        const { absoluteLabel } = this.props;
        const sourceContainer = super.containerProps();

        return {
            ...sourceContainer,
            absoluteLabel
        };
    }
}

export default FieldSelectContainer;
