/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import { SUB_MENU } from 'Component/Header/Header.config';
import { SIDE_MENU } from 'Component/SideMenu/SideMenu.config';
import MenuQuery from 'Query/Menu.query';
import {
    MenuContainer as SourceMenuContainer
} from 'SourceComponent/Menu/Menu.container';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import MenuHelper from 'Util/Menu';

/** @namespace Satisfly/Component/Menu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Satisfly/Component/Menu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setNavigationState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE))
});

/** @namespace Satisfly/Component/Menu/Container */
export class MenuContainer extends SourceMenuContainer {
    containerProps() {
        const {
            device,
            isTopHeaderVisible,
            handleSideMenuOpen
        } = this.props;
        const { activeMenuItemsStack, menu } = this.state;

        return {
            activeMenuItemsStack,
            menu,
            device,
            isTopHeaderVisible,
            handleOpenSideMenu: handleSideMenuOpen
        };
    }

    _getMenuOptions() {
        const { device } = this.props;
        const { header_content: { header_menu } = {} } = window.contentConfiguration;

        return {
            identifier: device?.isMobile ? SIDE_MENU : (header_menu || 'new-main-menu')
        };
    }

    _getMenu() {
        this.fetchData(
            [MenuQuery.getQuery(this._getMenuOptions())],
            ({ menu }) => {
                this.setState({
                    menu: MenuHelper.reduce(menu)
                });
            }
        );
    }

    handleSubcategoryClick(e, activeSubcategory) {
        const { setNavigationState, goToPreviousNavigationState } = this.props;
        const { activeMenuItemsStack } = this.state;
        const { item_id } = activeSubcategory;

        e.stopPropagation();

        const newActiveMenuItemsStack = this.getNewActiveMenuItemsStack(activeMenuItemsStack, item_id);

        this.setState({ activeMenuItemsStack: newActiveMenuItemsStack });

        if (Object.keys(activeSubcategory.children || {}).length) {
            setNavigationState({
                name: SUB_MENU,
                title: activeSubcategory.title,
                onBackClick: () => {
                    this.setState({ activeMenuItemsStack: [] });
                    goToPreviousNavigationState();
                }
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
