/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import AddIcon from 'Component/AddIcon';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import MinusIcon from 'Component/MinusIcon';
import { FieldNumberWithControls as SourceComponent } from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.component';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Extract';

import './FieldNumberWithControls.style.scss';
/**
 * Field Number With Controls
 * @class FieldNumberWithControls
 * @namespace Satisfly/Component/FieldNumberWithControls/Component */
export class FieldNumberWithControls extends SourceComponent {
    static propTypes = {
        ...super.propTypes,
        step: PropTypes.number.isRequired
    };

    render() {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
            value,
            events,
            setRef,
            stateValue,
            handleValueChange,
            isDisabled,
            step
        } = this.props;

        // eslint-disable-next-line no-magic-numbers
        const numberValue = Math.round((+value || +stateValue) * 100) / 100;
        const correctedValue = value;
        const additionaLabel = step < 1 ? __('m') : null;

        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                  value={ correctedValue }
                  type={ FIELD_TYPE.number }
                  readOnly
                  aria-label={ __('Value') }
                  disabled={ isDisabled }
                />
                { additionaLabel && (
                    <span block="AdditionalLabel">{ additionaLabel }</span>
                ) }
                <button
                  disabled={ max === 1 || numberValue >= max || isDisabled }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleValueChange(numberValue + step) }
                  aria-label={ __('Add') }
                  type={ FIELD_TYPE.button }
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                  disabled={ numberValue + 1 === min || numberValue <= min || isDisabled }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleValueChange(numberValue - step) }
                  aria-label={ __('Subtract') }
                  type={ FIELD_TYPE.button }
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }
}

export default FieldNumberWithControls;
