/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import CartQuery from 'Query/Cart.query';
import ConfigQuery from 'Query/Config.query';
import RegionQuery from 'Query/Region.query';
import {
    CartDispatcher,
    ConfigDispatcher as SourceConfigDispatcher
} from 'SourceStore/Config/Config.dispatcher';

export { CartDispatcher };

/** @namespace Satisfly/Store/Config/Dispatcher */
export class ConfigDispatcher extends SourceConfigDispatcher {
    prepareRequest() {
        return [
            RegionQuery.getCountriesQuery(),
            ConfigQuery.getQuery(),
            ConfigQuery.getCheckoutAgreements(),
            ConfigQuery.getCurrencyData(),
            ConfigQuery.getCurrencyRates(),
            CartQuery.getCartDisplayConfig()
        ];
    }
}

export default new ConfigDispatcher();
