/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CompareIcon.style';

/** @namespace Satisfly/Component/CompareIcon/Component */
export class CompareIcon extends PureComponent {
    static propTypes = {
        isActive: PropTypes.bool
    };

    static defaultProps = {
        isActive: false
    };

    render() {
        const { isActive } = this.props;

        return (
            <svg
              block="CompareIcon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              mods={ { isActive } }
            >
                <path d="M21 16.5908L17.7273 19.8636L14.4545 16.5908" stroke="#242424" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.8175 5.13647L14.4538 5.13647C15.3218 5.13647 16.1542 5.48128 16.768 6.09504C17.3818 6.70879 17.7266 7.54122 17.7266 8.40921L17.7266 19.8638" stroke="#242424" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.99946 8.40896L6.27219 5.13623L9.54492 8.40896" stroke="#242424" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.1819 19.8635L9.54552 19.8635C8.67754 19.8635 7.8451 19.5187 7.23135 18.905C6.61759 18.2912 6.27279 17.4588 6.27279 16.5908L6.27279 5.13623" stroke="#242424" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        );
    }
}

export default CompareIcon;
