/* eslint-disable spaced-comment,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import { FieldNumberWithControlsContainer } from 'Component/FieldNumberWithControls/FieldNumberWithControls.container';
import FieldSelectContainer from 'Component/FieldSelect/FieldSelect.container';
import { Field as SourceField } from 'SourceComponent/Field/Field.component';

import './Field.style';

/**
 * Field
 * @class Field
 * @namespace Satisfly/Component/Field/Component */
export class Field extends SourceField {
    static propTypes = {
        ...super.propTypes,
        forceCloseDropdown: PropTypes.bool,
        step: PropTypes.number,
        hideVariantsDialog: PropTypes.func
    };

    static defaultProps = {
        ...super.defaultProps,
        forceCloseDropdown: false,
        step: 1,
        hideVariantsDialog: () => {}
    };

    renderSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false,
            changeValueOnDoubleClick,
            isSortSelect,
            forceCloseDropdown,
            hideVariantsDialog,
            absoluteLabel
        } = this.props;

        return (
            <FieldSelectContainer
              attr={ attr }
              events={ events }
              options={ options }
              setRef={ setRef }
              isDisabled={ isDisabled }
              isSortSelect={ isSortSelect }
              changeValueOnDoubleClick={ changeValueOnDoubleClick }
              forceCloseDropdown={ forceCloseDropdown }
              hideVariantsDialog={ hideVariantsDialog }
              absoluteLabel={ absoluteLabel }
            />
        );
    }

    renderNumberWithControls() {
        const {
            attr,
            events,
            setRef,
            value,
            isDisabled = false,
            step
        } = this.props;

        return (
            <FieldNumberWithControlsContainer
              value={ value }
              attr={ attr }
              events={ events }
              setRef={ setRef }
              isDisabled={ isDisabled }
              step={ step }
            />
        );
    }
}

export default Field;
